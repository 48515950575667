import AutomatStatusService from "service/data-service/automate-controller/AutomatStatus.service";
import AutomatStatusList from "service/data-service/automate-controller/interface/AutomatStatus";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_AUTOMAT_STATUS = "FETCH_AUTOMAT_STATUS";
export const FETCH_AUTOMAT_STATUS_LOG = "FETCH_AUTOMAT_STATUS_LOG";

const configureAutomatStatusStore = () => {
  const fetchLatestStatus: AutomatAction = {
    identifier: FETCH_AUTOMAT_STATUS,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail?: Function
    ) => {
      AutomatStatusService.retrieveAutomatenStatus(
        payload.automatKey,
        10,
        (statusList: AutomatStatusList) =>
          callback({
            automatStatus: statusList,
          } as AutomatDataRecord),
        callbackOnFail as Function
      );
    },
  };
  const fetchStatusLog: AutomatAction = {
    identifier: FETCH_AUTOMAT_STATUS_LOG,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      AutomatStatusService.retrieveAutomatenStatus(
        payload.automatKey,
        payload.statusRecordCount,
        (statusList: AutomatStatusList) =>
          callback({
            automatStatusLog: statusList,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([
    fetchLatestStatus,
    fetchStatusLog,
  ]);
};

export default configureAutomatStatusStore;
