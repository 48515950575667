import { Optional } from "global/util/interface/MappedTypesTransform";
import AutomatPanelRoutes from "page/Automatensuche/components/Automatenblatt/components/AutomatDetailPanelContainer/routes";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AutomatViewHints } from "./interface/AutomatViewHintsController";

type AutomatViewHintStore = Array<AutomatViewHints>;
type AutomatViewHintsUpdateDispatch = (
  data: Optional<AutomatViewHints>
) => void;

interface AutomatViewHintListener {
  automatKey: string;
  listener: Dispatch<SetStateAction<AutomatViewHintStore>>;
}

let globalAutomatViewHintStore: AutomatViewHintStore = [];
let listeners: Array<AutomatViewHintListener> = [];

const updateAutomatViewHints = (
  data: Optional<AutomatViewHints>,
  hasListeners: boolean
) => {
  const automatKey = data.automatKey;
  const index = globalAutomatViewHintStore.findIndex(
    (e) => e.automatKey === automatKey
  );
  if (index === -1) {
    console.error(
      `AutomatViewHints with key ${automatKey} not found for update`
    );
    return;
  }
  const updatedDatastore = [...globalAutomatViewHintStore];
  updatedDatastore[index] = {
    ...globalAutomatViewHintStore[index],
    ...data,
  };
  if (!updatedDatastore[index].infoPanelIndex) {
    updatedDatastore[index].infoPanelIndex = 0;
  }
  globalAutomatViewHintStore = updatedDatastore;
  if (hasListeners === true) {
    const dataListeners = listeners.filter(
      (li) => li.automatKey === automatKey
    );
    for (const dataListener of dataListeners) {
      dataListener.listener(globalAutomatViewHintStore);
    }
  }
};

const removeAutomatViewHints = (automatKey: string) => {
  globalAutomatViewHintStore = globalAutomatViewHintStore.filter(
    (e) => e.automatKey !== automatKey
  );
};

const generateDefaultAutomatViewHint = (
  automatKey: string
): AutomatViewHints => {
  return {
    automatKey: automatKey,
    statusRecordCount: 10,
  } as AutomatViewHints;
};

const registerAutomatViewHints = (hints: Optional<AutomatViewHints>) => {
  let recordIndex = -1;

  if (!hints.automatKey) {
    throw new Error("AutomatViewHint must have an automatKey");
  }

  if (hints.automatKey)
    recordIndex = globalAutomatViewHintStore.findIndex(
      (e) => e.automatKey === hints.automatKey
    );

  if (recordIndex === -1) {
    recordIndex = globalAutomatViewHintStore.length;
    globalAutomatViewHintStore.push({
      ...generateDefaultAutomatViewHint(hints.automatKey),
    });
  }
  updateAutomatViewHints(hints, false);
};

export const AutomatViewHintsController = {
  registerAutomatViewHints,
  removeAutomatViewHints,
  panelViewRouteName: (automatKey: string): string => {
    let routeIndex = 0;
    const index = globalAutomatViewHintStore.findIndex(
      (e) => e.automatKey === automatKey
    );
    if (index !== -1) {
      routeIndex = globalAutomatViewHintStore[index].infoPanelIndex;
    }
    return "/" + Object.values(AutomatPanelRoutes)[routeIndex];
  },
};

export const useAutomatViewHints = (
  automatKey: string | undefined,
  shouldListen = true
): [AutomatViewHints, AutomatViewHintsUpdateDispatch] => {
  const setState = useState(globalAutomatViewHintStore)[1];

  let automatViewHintRecord: AutomatViewHints =
    generateDefaultAutomatViewHint("-none-");

  if (automatKey) {
    let recordIndex = globalAutomatViewHintStore.findIndex(
      (e) => e.automatKey === automatKey
    );

    if (recordIndex === -1) {
      recordIndex = globalAutomatViewHintStore.length;
      globalAutomatViewHintStore.push(
        generateDefaultAutomatViewHint(automatKey)
      );
    }
    automatViewHintRecord = globalAutomatViewHintStore[recordIndex];
  }

  const updateDispatch: AutomatViewHintsUpdateDispatch = (
    data: Optional<AutomatViewHints>
  ) => {
    updateAutomatViewHints(
      {
        ...data,
        automatKey: automatKey,
      },
      shouldListen
    );
  };

  useEffect(() => {
    if (automatKey && shouldListen) {
      listeners.push({
        automatKey: automatKey,
        listener: setState,
      });
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter((li) => li.listener !== setState);
      }
    };
  }, [automatKey, setState, shouldListen]);

  return [automatViewHintRecord, updateDispatch];
};
