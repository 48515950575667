import { Grid, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  enumeratorItemForId,
  enumeratorItemForValue,
} from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import "global/components/ui.scss";
import {
  TRACK_CHANGE,
  directPathMerger,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  AUTOMATENBLATT_CLEARER_ENUM_ID,
  AUTOMATENBLATT_KOMMART_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { Optional } from "global/util/interface/MappedTypesTransform";
import {
  returnUndefinedIfOnlyWhitespace,
  validateMacAddress,
} from "global/util/utils";
import { useContext, useEffect, useState } from "react";
import AutomatenblattInformation, {
  TKDaten,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import TKPartnerDetailCard, {
  ARTIKELSTAMM_TYPE_ID,
  DPGBTB_TYPE_ID,
  EDKBTB_TYPE_ID,
  LOGGING_TYPE_ID,
  MENGENMELDUNG_TYP_ID,
} from "./TKPartnerDetailCard";

interface TKPartnerProps extends PanelBaseProps {
  automatKey: string;
}

const TKPartnerPanel = (props: TKPartnerProps) => {
  const [automatData, dispatchAutomatData, dataWithChanges] = useAutomatData(
    props.automatKey
  );

  const accessContext = useContext(AccessContext);
  const accessObject = accessContext.getAccessContainer();

  const [partnerDetailsChangesAllowed, setPartnerDetailsChangesAllowed] =
    useState(true);

  const kommArtEnumerator = useEnumerator(AUTOMATENBLATT_KOMMART_ENUM_ID);
  const clearerEnumerator = useEnumerator(AUTOMATENBLATT_CLEARER_ENUM_ID);

  const onTKDatenAttibuteChange = (
    attributeName: string,
    valueWrapper: Optional<TKDaten>
  ) => {
    type ObjectKey = keyof TKDaten;
    const key = attributeName as ObjectKey;

    dispatchAutomatData(TRACK_CHANGE, {
      change: {
        path: `tkDaten.${attributeName}`,
        refValue: automatData.automatenblattInformation.tkDaten[key],
        value: valueWrapper[key],
        merger: directPathMerger,
      },
    } as AutomatActionPayload);
  };

  const onAutomatDataAttributeChange = (
    attributeName: string,
    valueWrapper: Optional<AutomatenblattInformation>
  ) => {
    type ObjectKey = keyof AutomatenblattInformation;
    const key = attributeName as ObjectKey;

    dispatchAutomatData(TRACK_CHANGE, {
      change: {
        path: attributeName,
        refValue: automatData.automatenblattInformation[key],
        value: valueWrapper[key],
        merger: directPathMerger,
      },
    } as AutomatActionPayload);
  };

  useEffect(() => {
    setPartnerDetailsChangesAllowed(
      accessObject.showAutomatenTabBtnAutomatSpeichern &&
        !automatData.automatenblattInformation.readonly
    );
  }, [accessContext, automatData.automatenblattInformation]);

  return (
    <Grid container spacing={5} width={"auto"}>
      <Grid item className="grid_item_30rem" xs={12} sm={5}>
        <SelectBox
          label="Kommunikations Art:"
          enumerator={kommArtEnumerator}
          selection={enumeratorItemForId(
            kommArtEnumerator,
            dataWithChanges.tkDaten.kommArt
          )}
          id="kommunikations-art"
          getSelectedValue={(value) => {
            onTKDatenAttibuteChange("kommArt", {
              kommArt: value?.id.toString(),
            });
          }}
          reset={props.resetValues}
        />

        <TextValue
          label="Adapter:"
          value={
            automatData.automatenblattInformation.automatCredentialList[0]
              .adapter
          }
          id="adapter"
          readOnly
        />
        <SelectBox
          label="Zuständiger Clearer:"
          enumerator={clearerEnumerator}
          selection={enumeratorItemForValue(
            clearerEnumerator,
            dataWithChanges.tkClearer
          )}
          id="zustandiger-clearer"
          getSelectedValue={(value) => {
            onAutomatDataAttributeChange("tkClearer", {
              tkClearer: value?.id.toString(),
            });
          }}
          reset={props.resetValues}
        />
        <Typography variant={"h5"}>LAN</Typography>

        <TextValue
          label="IP/DNS-Adresse:"
          value={dataWithChanges.tkDaten.ipV4Lan ?? ""}
          id="lan-ip4-or-dns-name"
          onChange={(event) =>
            onTKDatenAttibuteChange("ipV4Lan", {
              ipV4Lan:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
        />
        <TextValue
          label="Standard Gateway v4:"
          value={dataWithChanges.tkDaten.stdGatewayV4 ?? ""}
          id="lan-gw4"
          onChange={(event) =>
            onTKDatenAttibuteChange("stdGatewayV4", {
              stdGatewayV4:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
        />
        <TextValue
          label="Subnetz v4:"
          value={dataWithChanges.tkDaten.subnetzV4 ?? ""}
          id="lan-subnet4"
          onChange={(event) =>
            onTKDatenAttibuteChange("subnetzV4", {
              subnetzV4:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
        />
        <TextValue
          label="MAC-Adresse:"
          value={dataWithChanges.tkDaten.macAddress ?? ""}
          maxLength={17}
          onChange={(event) =>
            onTKDatenAttibuteChange("macAddress", {
              macAddress:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
          inputClassName={
            validateMacAddress(dataWithChanges.tkDaten.macAddress ?? "")
              ? ""
              : dataWithChanges.tkDaten.macAddress
              ? "valueHinweis"
              : ""
          }
        />
        <TextValue
          label="Switch Nr.:"
          value={dataWithChanges.tkDaten.switchNr ?? ""}
          onChange={(event) =>
            onTKDatenAttibuteChange("switchNr", {
              switchNr:
                returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
        />
        <DatePickerElement
          id="switch-number-gueltig-von"
          label="Switch Nr. gültig von:"
          defaultValue={
            DateUtils.parseIsoDateString(dataWithChanges.tkDaten.switchNrVon) ??
            undefined
          }
          getSelectedValue={(value) => {
            onTKDatenAttibuteChange("switchNrVon", {
              switchNrVon:
                DateUtils.formatDateToAPIDateString(value ?? undefined) ?? "",
            });
          }}
          datePickerProperties={{
            minDate: getTomorrow(),
            disablePast: true,
          }}
        />

        <TextValue
          label="Port Nr.:"
          value={dataWithChanges.tkDaten.portNr ?? ""}
          maxLength={5}
          onChange={(event) =>
            onTKDatenAttibuteChange("portNr", {
              portNr: returnUndefinedIfOnlyWhitespace(event.target.value) ?? "",
            })
          }
        />
        <DatePickerElement
          id="port-number-gueltig-von"
          label="Port Nr. gültig von:"
          defaultValue={
            DateUtils.parseIsoDateString(dataWithChanges.tkDaten.portNrVon) ??
            undefined
          }
          getSelectedValue={(value) => {
            onTKDatenAttibuteChange("portNrVon", {
              portNrVon:
                DateUtils.formatDateToAPIDateString(value ?? undefined) ?? "",
            });
          }}
          datePickerProperties={{
            minDate: getTomorrow(),
            disablePast: true,
          }}
        />
      </Grid>
      <Grid item className="grid_item_40rem" xs={12} sm={7}>
        <TKPartnerDetailCard
          label={"Mengenmeldung"}
          referenceData={automatData}
          dispatchAutomatDataChange={dispatchAutomatData}
          automatenblattInformation={dataWithChanges}
          typ_id={MENGENMELDUNG_TYP_ID}
          resetValues={props.resetValues}
          visibilitySettings={{
            changesAllowed: partnerDetailsChangesAllowed,
            ...accessObject.tkpartnerMengenmeldung,
          }}
        />
        <TKPartnerDetailCard
          label={"Artikelstamm"}
          referenceData={automatData}
          dispatchAutomatDataChange={dispatchAutomatData}
          automatenblattInformation={dataWithChanges}
          typ_id={ARTIKELSTAMM_TYPE_ID}
          resetValues={props.resetValues}
          visibilitySettings={{
            changesAllowed: partnerDetailsChangesAllowed,
            ...accessObject.tkpartnerArtikelstamm,
          }}
        />
        <TKPartnerDetailCard
          label={"DPG-BTB"}
          referenceData={automatData}
          dispatchAutomatDataChange={dispatchAutomatData}
          automatenblattInformation={dataWithChanges}
          typ_id={DPGBTB_TYPE_ID}
          resetValues={props.resetValues}
          visibilitySettings={{
            changesAllowed: partnerDetailsChangesAllowed,
            ...accessObject.tkpartnerDpgBtb,
          }}
        />
        <TKPartnerDetailCard
          label={"RN-BTB"}
          referenceData={automatData}
          dispatchAutomatDataChange={dispatchAutomatData}
          automatenblattInformation={dataWithChanges}
          typ_id={EDKBTB_TYPE_ID}
          resetValues={props.resetValues}
          visibilitySettings={{
            changesAllowed: partnerDetailsChangesAllowed,
            ...accessObject.tkpartnerEdkBtb,
          }}
        />
        <TKPartnerDetailCard
          label={"Logfile"}
          referenceData={automatData}
          dispatchAutomatDataChange={dispatchAutomatData}
          automatenblattInformation={dataWithChanges}
          typ_id={LOGGING_TYPE_ID}
          resetValues={props.resetValues}
          visibilitySettings={{
            changesAllowed: partnerDetailsChangesAllowed,
            ...accessObject.tkpartnerLogfile,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TKPartnerPanel;
