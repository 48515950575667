const MESSAGES = {
  NO_AUTOMAT_FOUND_FOR_SERIAL: (serialNumber: string) =>
    `Kein Automat mit der Seriennummer ${serialNumber} gefunden`,

  DATA_LOAD_ERROR: "Beim Laden der Daten ist ein Fehler aufgetreten!",
  DATA_LOAD_NETWORK_ERROR:
    "Beim Abruf der Daten ist ein Netzwerkfehler aufgetreten!",
  DATA_FILE_LOAD_ERROR: "Beim Dateiabruf ist ein Fehler aufgetreten!",

  FIREWALL_SUPPORT_MESSAGE: (supportId: string) =>
    `Die Anfrage wurde von der Firewall abgelehnt. Bitte kopieren und melden Sie die unten aufgeführte Session ID an die EPG: \n\n${supportId}`,

  FIREWALL_ERROR_TITLE: "Firewall Fehler",
  // change tracking
  CHANGES_UNSAVED_ENTRIES: "Ungespeicherte Änderungen vorhanden!",
  CHANGES_UNSAVED_BEFORE_CLOSE_TITLE:
    "Der Automat, den Sie versuchen zu schließen, hat ungespeicherte Änderungen. Bitte speichern Sie diese, bevor Sie den Automaten schließen.",
  //Notizen
  NOTIZ_MISSING_TYPE: "Bitte einen Notiz Typ auswählen",
  NOTIZ_MISSING_STATUS: "Bitte einen Notiz Status auswählen",
  NOTIZ_MISSING_TEXT: "Bitte einen Text für die Notiz eingeben",
  NOTIZ_MISSING_VORGANG_NR:
    "Bitte eine Vorgangs-Nummer für die Unplausible Rechnung eingeben",
  // Unplausible rechnungen
  UNPLAUSIBLE_NO_AUTOMAT_GLN: "Kein Automat für diese RNS-GLN gefunden",
  UNPLAUSIBLE_MISSING_IDENTIFIER:
    "Entweder Seriennummer oder RNS-GLN muss angegeben werden",
  UNPLAUSIBLE_NO_AUTOMAT_KEY:
    "Automat konnte nicht eindeutig identifiziert werden",
  UNPLAUSIBLE_ERROR_PROCESSING: "Verarbeitung fehlgeschlagen",
  UNPLAUSIBLE_UNEXPECTED_PATH: "Unerwarteter Programmablauf",
  UNPLAUSIBLE_LOADING_RECORDS: "Unplausible Rechnungen werden geladen...",
  UNPLAUSIBLE_STATUS_CHANGE_TITLE: "Status change operation",
  UNPLAUSIBLE_RECORD_CHANGE_COUNT: (count: number) =>
    `${count} records have been updated.`,
  // Buttons
  BUTTON_COPY: "Kopieren",
};

export default MESSAGES;
