import MESSAGES from "global/messages";
import DepositMachineDataService from "service/data-service/automate-controller/DepositMachineData.service";

export default function findMachineKeyViaSerialNumber(
  automatSerienNr: string,
  callbackWithAutomatKey: (automatKey: string) => void
) {
  DepositMachineDataService.retrieveDepositMachineData(
    {
      serienNr: automatSerienNr,
      macAddress: "",
    },
    (responseData) => {
      if (responseData && responseData.length > 0)
        callbackWithAutomatKey(responseData[0].automatkey);
      else callbackWithAutomatKey("");
    },
    (error: any) => {
      console.warn(error);
      callbackWithAutomatKey("");
    }
  );
}

export function findMachineKeyViaSerialNumberAsync(
  serialNumber: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    findMachineKeyViaSerialNumber(serialNumber, (key) => {
      if (key) resolve(key);
      else
        reject(new Error(MESSAGES.NO_AUTOMAT_FOUND_FOR_SERIAL(serialNumber))); //"No automat found for the given serial number"
    });
  });
}
