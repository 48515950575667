import HttpService, { EpcomResponse } from "App/service/http.service";
import Config from "Config";
import useModalController from "global/hook/modal/use-modals";
import DateUtils from "global/util/DateUtils";
import fileDownload from "js-file-download";
import DepositMachineFilter from "service/data-service/automate-controller/interface/FilterObject";

export enum NotizenSuchoption {
  wiedervorlage = "wiedervorlage",
  notizSuchmaske = "notizSuchmaske",
}

const downloading = "Wird heruntergeladen....";

const ExportService = {
  downloadSearchResult: (filterObject: DepositMachineFilter) => {
    ExportPost("automat-search", "PFD_Export", filterObject);
  },
  downloadAutomatStatus: (automatKey: string, limit: number) => {
    ExportGet(
      `automat-status/${automatKey}/${limit}`,
      "PFD_Export_Automatstatus"
    );
  },
  downloadAutomatLogfile: (
    automatSerienNr: string,
    datumVon: string, // format dd.MM.YYYY
    datumBis: string, // format dd.MM.YYYY
    kategorien: string
  ) => {
    ExportGet("automat-logfiles", "AutomatLogfiles", {
      automatSerienNr: automatSerienNr,
      datumVon: datumVon,
      datumBis: datumBis,
      kategorien: kategorien,
    });
  },
  downloadNotizen: (suchoption: NotizenSuchoption, suchparam: string) => {
    ExportGet(`notizen/${suchoption}/${suchparam}`, "PFD_Export_Notizen");
  },
  downloadKaufanfragen: (statumsen: string) => {
    ExportGet("kaufanfragen", "Kaufanfragen", { statumsen: statumsen });
  },
  downloadFehlerhafteMM: (automatKey?: string | null) => {
    ExportGet("fehlerhafte-mengenmeldungen", "PFD_Fehlerhafte_MM_Export");
  },
  downloadNokZuOk: (automatKey?: string | null) => {
    ExportGet("nok-zu-ok", "PFD_Export_Automaten_NOK_zu_OK");
  },
  downloadErreichbarkeit: () => {
    ExportGet("erreichbarkeit", "Erreichbarkeit");
  },
  downloadZurBearbeitungAnstehend: () => {
    ExportGet("zur-bearbeitung-anstehend", "ZurBearbeitungAnstehend");
  },
  downloadFlatFile: (
    rnsGln: string,
    serienNr: string,
    aktiveAutomaten: string
  ) => {
    ExportGet(
      "flatfile",
      "PFD_Flatfile_" +
        DateUtils.formatDateToAPIDateString(new Date(), "yyyy_MM_dd"),
      {
        rnsGln: rnsGln,
        serienNr: serienNr,
        aktiveAutomaten: aktiveAutomaten,
      }
    );
  },
  downloadAuslastungVerfuegbarkeit: (
    rnsGln: string,
    serienNr: string,
    aktiveAutomaten: string
  ) => {
    ExportGet("auslastungverfuegbarkeit", "PFD_Verfuegbarkeitsanalyse", {
      rnsGln: rnsGln,
      serienNr: serienNr,
      aktiveAutomaten: aktiveAutomaten,
    });
  },
  downloadRnBTBDSMDFailedToday: () => {
    ExportGet("rnbtb/dsmdfailedtoday", "RnBTBDSMDFailedToday");
  },
  ipRange: () => {
    ExportGet("erreichbarkeitperiprange", "PFD_Export_IP_Bereiche");
  },
  ipWhitelist: () => {
    ExportGet("ipaddresswhitelist", "PFD_Export_IP_Whitelist");
  },
  downloadAutomatWithTooManyFilesData: () => {
    ExportGet("archiveToManyFilesData", "PFD_AutomatenDaten_GT30");
  },
  positionenOhneWgr: () => {
    ExportGet("positionenOhneWgr", "PFD_Export_Positionen_Ohne_WGR");
  },
  downloadUnplausibleRechnungen: (statumsen: string) => {
    ExportGet("sap-unplausible-rechnungen", "Export_Unplausible_Rechnungen", {
      status: statumsen,
    });
  },
  downloadSapPositions: (
    dateFrom: string, // 2021-01-01
    dateTo: string, // 2021-01-01
    serienNr: string,
    referenzNr: string,
    warengruppeNr: string,
    unterWarengruppeId: string,
    rnsGln: string
  ) => {
    ExportGet(
      "sap-positionen",
      "PFD_Export_SapRechnungsdaten_GETALLPOSITIONS_EXPORT",
      {
        dateFrom: dateFrom,
        dateTo: dateTo,
        serienNr: serienNr,
        referenzNr: referenzNr,
        warengruppeNr: warengruppeNr,
        unterWarengruppeId: unterWarengruppeId,
        rnsGln: rnsGln,
      }
    );
  },
};

const ExportGet = (url: string, fileName: string, params?: object) => {
  const dialogController = useModalController();
  dialogController.showLoadingDialog(downloading);
  HttpService.api
    .sendGet(`${Config.reportServiceUrl}export/${url}`, {
      responseType: "arraybuffer",
      params: {
        ...params,
      },
    })
    .then((response: EpcomResponse) => {
      fileDownload(
        response.data,
        `${fileName}.xlsx`,
        "application/vnd.ms-excel"
      );
    })
    .catch((error) => console.error("ExportGet", error))
    .finally(() => {
      dialogController.closeModal();
    });
};

const ExportPost = (
  url: string,
  fileName: string,
  request: object,
  params?: object
) => {
  const dialogController = useModalController();
  dialogController.showLoadingDialog(downloading);
  HttpService.api
    .sendJsonDataPost(`${Config.reportServiceUrl}export/${url}`, request, {
      responseType: "arraybuffer",
      params: {
        ...params,
      },
    })
    .then((response: EpcomResponse) => {
      fileDownload(
        response.data,
        `${fileName}.xlsx`,
        "application/vnd.ms-excel"
      );
    })
    .catch((error) => console.error("ExportPost", error))
    .finally(() => {
      dialogController.closeModal();
    });
};

export default ExportService;
