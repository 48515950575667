import { Button, Grid, Stack, Typography } from "@mui/material";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import React, { useEffect, useState } from "react";
import JobMonitoringHistoryRequestBody from "service/data-service/job-controller/interface/JobMonitoringHistoryRequestBody";
import JobsEnumerator, {
  JOB_ALL,
  JOB_SAPRD_POSIMPORT,
  JOB_SAPRD_SAPIMPORT,
} from "../utils/job-list";
import { getStatusFilterListFromIds } from "../utils/status-filters";
import JobMonitoringDashboard from "./JobMonitoringDashboard";
import JobMonitoringSearch from "./JobMonitoringSearch";

import AppTheme from "App/theme/AppTheme";
import DataGridRowsDataDef from "global/components/UI/DataGrid/interface/DataGridRowsDataDef";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { useModalDialog } from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils from "global/util/DateUtils";
import JobMonitoringHistoryDTO from "service/data-service/job-controller/interface/JobMonitoringHistoryDTO";
import JobMonitoringController from "../JobController";
import StatusFilterItems from "../utils/status-filters";
import JobDetailsDialogContent, {
  JobMonitoringDetails,
} from "./JobDetailsDialogContent";
import "./jobmonitoring.scss";

const JobPage: React.FC = () => {
  const [dialog, dialogController] = useModalDialog("job-monitoring");
  //
  const windowViewport = useWindowViewport(0, 0);
  const [gridHeight, setGridHeight] = useState<number>(400);
  //
  const [showJobMonitoringDashboard, setShowJobMonitoringDashboard] =
    useState(true);

  const [startDatePickerKey, setStartDatePickerKey] = useState("");
  const [endDatePickerKey, setEndDatePickerKey] = useState("");
  const [job, setJob] = useState<EnumeratorItem | null>();
  const [selectedStartDate, setSelectedStartDate] =
    React.useState<Date | null>();
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>();
  const [selectedHistoryDayDepth, setSelectedHistoryDayDepth] =
    React.useState<string>("");

  const emptyJobRecords: DataGridRowsDataDef<JobMonitoringHistoryDTO> = {
    data: [],
    isFetchingData: false,
  };
  const [searchData, setSearchData] =
    useState<DataGridRowsDataDef<JobMonitoringHistoryDTO>>(emptyJobRecords);

  const [reset, setReset] = useState(false);

  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<
    EnumeratorItem[]
  >(StatusFilterItems.items);

  const handleSelectedStatusChange = (values: EnumeratorItem[]): void => {
    setSelectedStatusFilter(values);
  };

  const handleSelectedHistoryDayDepth = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedHistoryDayDepth(event.target.value);
  };

  const handleSelectedStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };

  const handleSelectedEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const handleResetButton = () => {
    setShowJobMonitoringDashboard(true);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedHistoryDayDepth("");
    setSelectedStatusFilter(StatusFilterItems.items);
    setStartDatePickerKey(Date.now() + "");
    setEndDatePickerKey(Date.now() + "");
    setSearchData(emptyJobRecords);
    setReset((state) => !state);
  };

  const buildJobSearchRequestBody = (): JobMonitoringHistoryRequestBody => {
    let selectedStatus: string[] =
      getStatusFilterListFromIds(selectedStatusFilter);

    let startDate;
    let endDate;

    if (selectedStartDate && selectedEndDate) {
      if (selectedStartDate < selectedEndDate) {
        startDate = DateUtils.formatDateToAPIDateString(selectedStartDate);
        endDate = DateUtils.formatDateToAPIDateString(selectedEndDate);
      } else {
        endDate = DateUtils.formatDateToAPIDateString(selectedStartDate);
        startDate = DateUtils.formatDateToAPIDateString(selectedEndDate);
      }
    } else {
      if (selectedEndDate)
        endDate = DateUtils.formatDateToAPIDateString(selectedEndDate);
      if (selectedStartDate)
        startDate = DateUtils.formatDateToAPIDateString(selectedStartDate);
    }

    let jobType = job ? (job.id as string) : null;
    let sapJobType = null;

    if (
      jobType === null ||
      jobType === "" ||
      jobType === JOB_SAPRD_POSIMPORT.id ||
      jobType === JOB_SAPRD_SAPIMPORT.id ||
      jobType === JOB_ALL.id
    ) {
      sapJobType =
        jobType === null || jobType === "" ? (JOB_ALL.id as string) : jobType;
      jobType = null;
    }

    return {
      startDate: selectedStartDate ? startDate : null,
      endDate: selectedEndDate ? endDate : null,
      historyDayDepth: selectedHistoryDayDepth
        ? parseInt(selectedHistoryDayDepth)
        : null,
      jobType: jobType,
      sapJobType: sapJobType,
      selectedJobStatus: selectedStatus.length > 0 ? selectedStatus : null,
    };
  };

  const onJobSearchRecords = (records: Array<JobMonitoringHistoryDTO>) => {
    setSearchData({
      data: [...records],
      isFetchingData: false,
    });
  };

  const callbackOnFail = () => {
    setSearchData(emptyJobRecords);
  };

  const onFetchingJobSearchRecords = () => {
    setSearchData({
      data: searchData.data,
      isFetchingData: true,
    });
  };

  const handleSearchButton = () => {
    onFetchingJobSearchRecords();
    setShowJobMonitoringDashboard(false);
    JobMonitoringController.loadJobMonitoringHistoryJobs(
      buildJobSearchRequestBody(),
      onJobSearchRecords,
      callbackOnFail
    );
  };

  useEffect(() => {
    setGridHeight(
      windowViewport.height -
        (windowViewport.width > AppTheme.breakpoints.values.xl ? 372 : 428)
    );
  }, [windowViewport]);

  const rowClickHandler = (entry: JobMonitoringDetails) => {
    dialogController.showDialog({
      message: "",
      title: "Job Details",
      content: <JobDetailsDialogContent entry={entry} />,

      onAbortClick: () => {},
      abortCaption: "Abbrechen",
      maxWidth: "lg",
    });
  };

  return (
    <>
      <Grid container spacing={2} columnSpacing={6}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>JOB Monitoring</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
          <SelectBox
            label={"Jobauswahl:"}
            enumerator={JobsEnumerator}
            id="anlagerechnungsnehmer-region"
            getSelectedValue={(value) => setJob(value)}
            reset={reset}
            TypographyProps={{
              className: "jobmonitoring__LabelStyle",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={4}
          order={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 4 }}
        >
          <TextValue
            value={selectedHistoryDayDepth}
            id="historyDayDepth-selection"
            type="number"
            TypographyProps={{
              className: "jobmonitoring__LabelStyle",
            }}
            onChange={handleSelectedHistoryDayDepth}
            placeholder="0 (default)"
            label="Tagesanzahl für Jobauswahl"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={4}
          order={{ xs: 3, lg: 2 }}
        >
          <DatePickerElement
            label="Starttag auswählen"
            id="date-selection-startdate-picker"
            getSelectedValue={handleSelectedStartDateChange}
            key={startDatePickerKey}
            TypographyProps={{
              className: "jobmonitoring__LabelStyle",
            }}
          ></DatePickerElement>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={4}
          order={{ xs: 4, xl: 3 }}
        >
          <DatePickerElement
            label="Endtag auswählen"
            id="date-selection-enddate-picker"
            getSelectedValue={handleSelectedEndDateChange}
            key={endDatePickerKey}
            TypographyProps={{
              className: "jobmonitoring__LabelStyle",
            }}
          ></DatePickerElement>
        </Grid>
        <Grid item xs={12} xl={8} order={{ xs: 5 }}>
          <MultipleSelectCheckbox
            label={"Statusfilter"}
            options={StatusFilterItems.items}
            defaultValues={selectedStatusFilter.map((item) => item.id)}
            id="status-filter-selection"
            resetSelectedValues={reset}
            onChange={handleSelectedStatusChange}
            TypographyProps={{
              className: "jobmonitoring__LabelStyle",
            }}
            AutocompleteProps={{ className: "jobmonitoring__MultiInputStyle" }}
            StackProps={{ columnGap: 2 }}
          />
        </Grid>
        <Grid item xs={12} order={{ xs: 6 }}>
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              variant="secondary"
              onClick={handleResetButton}
            >
              Felder zurücksetzen
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={handleSearchButton}
            >
              Suchen
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {showJobMonitoringDashboard ? (
        <JobMonitoringDashboard
          gridHeight={gridHeight}
          rowClickHandler={rowClickHandler}
        />
      ) : (
        <JobMonitoringSearch
          gridDataDef={searchData}
          gridHeight={gridHeight}
          rowClickHandler={rowClickHandler}
        />
      )}
      {dialog && <ModalMessageDialog {...dialog} />}
    </>
  );
};

export default JobPage;
