import DateUtils from "global/util/DateUtils";
import LogfileService from "service/logfile-service/Logfile.service";
import LogfileZeile from "service/logfile-service/interface/LogfileZeile";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_LOGFILE_RECORDS = "FETCH_LOGFILE_RECORDS";

const configureAutomatLogfileStore = () => {
  const fetchLogfileRecords: AutomatAction = {
    identifier: FETCH_LOGFILE_RECORDS,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      LogfileService.loadByFilters(
        `${currentState.automatenblattInformation.seriennummer}`,
        DateUtils.formatDateToAPIDateString(
          payload.logfileDateFrom,
          "dd.MM.yyyy"
        ) ?? "",
        DateUtils.formatDateToAPIDateString(
          payload.logfileDateTo,
          "dd.MM.yyyy"
        ) ?? "",
        payload.logfileKategory.map((e) => e.id).join(","),
        (logfileRecords: Array<LogfileZeile>) =>
          callback({
            logfileRecords: logfileRecords,
          } as AutomatDataRecord),
        callbackOnFail
      );
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([fetchLogfileRecords]);
};

export default configureAutomatLogfileStore;
