import {
  automatCredentialsChangeMerger,
  tkDetailsChangeMerger,
  TRACK_CHANGE,
} from "global/hook/datastore/automat/change-tracker-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import AutomatChangeInfo from "global/hook/datastore/interface/AutomatChangeInfo";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { isPartialFilled as isPartiallyFilled } from "global/util/textFieldValidation/textFieldValidation";
import AutomatenblattInformation, {
  AutomatCredentials,
  KommTypen,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { ARTIKELSTAMM_TYPE_ID } from "../TKPartnerDetailCard";

const KOMMTYPES_CONTAINED_IN_TKPARTNER = [
  "AT_MM",
  "CD_STD",
  "AT_DPGBTB",
  "AT_EDKBTB",
  "AT_LOG",
];
export interface TKDetailsInformation {
  detail?: Optional<KommTypen>;
  credentialsInfo?: Optional<AutomatCredentials>;
  isArtikelStammdatenDetail: boolean;
}

const TkPartnerController = {
  /**
   * Checks if provided tkPartner detail is fully populated.
   *
   * @param tkDetailsInformation
   */
  isTkPartnerDetailIncomplete: function (
    tkDetailsInformation: TKDetailsInformation
  ): boolean {
    if (tkDetailsInformation.credentialsInfo?.activated === false) {
      return false;
    }
    let arrayToTest = [
      tkDetailsInformation.detail?.kommPartnerName,
      tkDetailsInformation.detail?.ftpDirectory,
      tkDetailsInformation.credentialsInfo?.username,
      tkDetailsInformation.credentialsInfo?.password,
    ];
    if (tkDetailsInformation.isArtikelStammdatenDetail) {
      arrayToTest.push(tkDetailsInformation.detail?.artikelstammVariante);
    }

    return isPartiallyFilled(arrayToTest);
  },
  /**
   * Checks if something is not filled out completely in Automatenblatt
   * @param automatenblattInformation
   */
  isTkPartnerIncomplete: function (
    automatenblattInformation: AutomatenblattInformation
  ): boolean {
    let hasIncompleteDetail = false;

    if (automatenblattInformation.seriennummer !== 0) {
      let dataInAllTKPartnerDetailCards = KOMMTYPES_CONTAINED_IN_TKPARTNER.map(
        (kommType) =>
          TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
            automatenblattInformation,
            kommType
          )
      );

      dataInAllTKPartnerDetailCards.forEach((oneTKDetails) => {
        if (this.isTkPartnerDetailIncomplete(oneTKDetails)) {
          hasIncompleteDetail = true;
        }
      });
    }
    return hasIncompleteDetail;
  },
  /**
   * Maps the automatenblattInformation.kommTypes of the corresponding typ_id
   * to an object of type TKDetailsInformation
   * @param automatenblattInformation
   * @param typ_id
   * @returns An Object
   */
  mapAutomatenblattInformationToTkDetailsInformation: function (
    automatenblattInformation: AutomatenblattInformation,
    typ_id: string
  ): TKDetailsInformation {
    const kommTypen = automatenblattInformation.kommTypen;
    const detail = kommTypen.find((e) => e.kommTyp === typ_id);
    const automatCredentialList =
      automatenblattInformation.automatCredentialList;
    const credentialsInfo = automatCredentialList.find(
      (e) => e.kommTyp.id === typ_id
    );
    const isArtikelStammdatenDetail = typ_id === ARTIKELSTAMM_TYPE_ID;

    return {
      detail: detail,
      credentialsInfo: credentialsInfo,
      isArtikelStammdatenDetail: isArtikelStammdatenDetail,
    };
  },
  registerAutomatCredentialChange: function (
    typ_id: string,
    attributeName: string,
    change: AutomatChangeInfo,
    dispatchAutomatData: DispatchAutomatDataFunction
  ) {
    dispatchAutomatData(TRACK_CHANGE, {
      change: {
        ...change,
        path: `automatCredential:${typ_id}:.${attributeName}`,
        merger: automatCredentialsChangeMerger,
      },
    } as AutomatActionPayload);
  },
  registerTKDetailsChange: function (
    typ_id: string,
    attributeName: string,
    change: AutomatChangeInfo,
    dispatchAutomatData: DispatchAutomatDataFunction
  ) {
    dispatchAutomatData(TRACK_CHANGE, {
      change: {
        ...change,
        path: `kommTypen:${typ_id}:.${attributeName}`,
        merger: tkDetailsChangeMerger,
      },
    } as AutomatActionPayload);
  },
};

export default TkPartnerController;
