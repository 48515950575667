import MESSAGES from "global/messages";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { UnplausibleRechnungRecord } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import ModalController from "../modal/interface/ModalController";
import useModalController from "../modal/use-modals";
import {
  RechnungenActionPayload,
  RechnungenStore,
  RechnungenStoreData,
} from "./interface/RechnungenStore";

export const RECHNUNGEN_STATUS_SEARCH = "RECHNUNGEN_STATUS_SEARCH";
export const RECHNUNGEN_STATUS_ITEMS = "RECHNUNGEN_STATUS_ITEMS";
export const RECHNUNGEN_UPDATED_ITEMS = "RECHNUNGEN_UPDATED_ITEM";
export const RECHNUNGEN_RESET_ACTIVE_ID = "RECHNUNGEN_RESET_ACTIVE_ID";

let rechnungenStatusStore: RechnungenStore = {
  data: {
    statusItems: [],
    result: [],
    activeEventId: -1,
  },
};
let listeners: Array<Dispatch<SetStateAction<RechnungenStoreData>>> = [];

const unplausibleRechnungRecordCompare = (
  a: UnplausibleRechnungRecord,
  b: UnplausibleRechnungRecord
) => {
  const glnTest = -1 * a.rnsGln.localeCompare(b.rnsGln);
  return glnTest === 0 ? a.eventId - b.eventId : glnTest;
};

const updateStoreData = (data?: RechnungenStoreData) => {
  const newData = data ?? {
    statusItems: [],
    result: [],
    activeEventId: -1,
  };
  newData.result = newData.result.sort((a, b) =>
    unplausibleRechnungRecordCompare(a, b)
  );
  rechnungenStatusStore.data = newData;
  listeners.forEach((l) => l(newData));
};

export type DispatchRechnungenFunction = (
  actionIdentifier: string,
  payload?: RechnungenActionPayload,
  callbackOnFail?: Function
) => void;

const rechnungenReducer = async (
  actionIdentifier: string,
  modals: ModalController,
  payload?: RechnungenActionPayload,
  callbackOnFail?: Function
) => {
  switch (actionIdentifier) {
    case RECHNUNGEN_STATUS_SEARCH:
      if (rechnungenStatusStore.data.statusItems.length === 0) {
        updateStoreData();
        return;
      }
      modals.showLoadingDialog(MESSAGES.UNPLAUSIBLE_LOADING_RECORDS); //"Unplausible Rechnungen werden geladen..."

      const data = await UnplausibleRechnungenService.suche(
        rechnungenStatusStore.data.statusItems
      );
      modals.closeModal();
      updateStoreData({
        ...rechnungenStatusStore.data,
        result: data,
      });
      break;
    case RECHNUNGEN_STATUS_ITEMS:
      rechnungenStatusStore.data = {
        ...rechnungenStatusStore.data,
        statusItems: payload?.queryStatusItems ?? [],
      };
      break;
    case RECHNUNGEN_RESET_ACTIVE_ID:
      rechnungenStatusStore.data = {
        ...rechnungenStatusStore.data,
        activeEventId: -1,
      };
      break;
    case RECHNUNGEN_UPDATED_ITEMS:
      if (!payload?.records) return;
      const activeStatusItems = rechnungenStatusStore.data.statusItems.map(
        (item) => item.id
      );
      const updatedRecords = payload!.records.filter((record) =>
        activeStatusItems.includes(record.status)
      );
      const removedIds = payload!.records
        .filter((record) => !activeStatusItems.includes(record.status))
        .map((record) => record.id);

      const storeRecords = [...rechnungenStatusStore.data.result].filter(
        (record) => !removedIds.includes(record.id)
      );

      updatedRecords.forEach((record) => {
        const index = storeRecords.findIndex((r) => r.id === record.id);
        if (index !== -1) storeRecords[index] = record;
      });

      updateStoreData({
        ...rechnungenStatusStore.data,
        result: storeRecords,
        activeEventId:
          updatedRecords.length > 0 ? updatedRecords[0].eventId : -1,
      });

      break;
    default:
      break;
  }
};

export const useUnplausibleRechnungen = (
  shouldListen = true
): [RechnungenStoreData, DispatchRechnungenFunction] => {
  const setState = useState<RechnungenStoreData>(rechnungenStatusStore.data)[1];

  const modals = useModalController();

  const dispatch = useCallback(
    (
      actionIdentifier: string,
      payload?: RechnungenActionPayload,
      callbackOnFail?: Function
    ) => rechnungenReducer(actionIdentifier, modals, payload, callbackOnFail), // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (shouldListen) listeners.push(setState);

    return () => {
      listeners = listeners.filter((li) => li !== setState);
    }; // eslint-disable-next-line
  }, [setState]);

  return [rechnungenStatusStore.data, dispatch];
};
