import HttpService from "App/service/http.service";
import Config from "Config";
import FilterController from "page/Automatensuche/components/FilterSystem/controller/FilterController";
import Row from "page/Automatensuche/interface/RowTypes";
import DepositMachineFilter from "./interface/FilterObject";

const DepositMachineDataService = {
  retrieveDepositMachineData: (
    filterObject: DepositMachineFilter,
    callbackOnSuccess: (data: Array<Row>) => void,
    callbackOnFail?: (error: any) => void
  ) => {
    HttpService.api
      .sendJsonDataPost(Config.dataServiceUrl + "automate/search", filterObject)
      .then((response) => {
        callbackOnSuccess(response.data);
      })
      .catch((error) => {
        if (callbackOnFail) callbackOnFail(error);
      });
  },
  retrieveMarketMachineData: async (rnsGln: string): Promise<Array<Row>> => {
    const rnsGlnFilter = FilterController.getDefaultFilterObject();
    rnsGlnFilter.rnsGln = rnsGln;

    return new Promise((resolve, reject) => {
      DepositMachineDataService.retrieveDepositMachineData(
        rnsGlnFilter,
        (data: Array<Row>) => resolve(data),
        (error: any) => reject(error)
      );
    });
  },
};

export default DepositMachineDataService;
