import HttpService from "App/service/http.service";
import Config from "Config";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import { RechnungenSucheResult } from "global/hook/rechnungen/interface/RechnungenStore";
import DateUtils from "global/util/DateUtils";
import { RechnungItem } from "page/rechnungATH/unplausibleRechnungen/controller/UnplausibleRechnungenController";
import {
  AutomatUnplausibleRechnungItem,
  UnplausibleRechnungRecord,
  UnplausibleRechnungRecordRaw,
} from "./interface/UnplausibleRechnungRecord";

const baseUrl: string = "unplausible-rechnungen";

const transmuteUnplausibleRechnungEvent = (
  item: UnplausibleRechnungRecordRaw
): UnplausibleRechnungRecord => {
  return {
    id: item.id,
    positionId: item.position.id,
    rnsGln: item.position.rnsGln ?? "",
    eventId: item.vorgangNr,
    eventName: item.ruleName,
    seriennr: item.position.serienNr,
    kurzText: item.position.kurzText,
    warengruppe: item.position.warengruppeName ?? "",
    unterWarengruppe: item.position.unterWarengruppeName ?? "",
    belegdatum: item.position.belegDatum,
    fakturierteMenge: item.position.fakturierteMenge,

    lieferantenArtikelNr: item.position.lieferantenArtikelNr,
    referenz: item.position.referenz,
    positionLieferant: item.position.positionLieferant,
    regulierungsBeleg: item.position.regulierungsBeleg,
    anlagen: item.position.anlagen,
    region: item.position.region,
    status: item.status,
    nettoStuckpreis: item.position.nettoWert,
    leistungsdatum: item.position.lieferLeistungsDatum,
    latestNote: item.noteText,
    noteId: item.note,
    noteKey: item.notizKey,
    noteAutomatSerialNumber: item.notizSerienNr,
    notizVorhandenSeit: DateUtils.optionalTimeArrayToGermanString(
      item.noteDate
    ),
  } as UnplausibleRechnungRecord;
};

const transmuteUnplausibleRechnungEvents = (
  sucheResult: RechnungenSucheResult
): Array<UnplausibleRechnungRecord> => {
  if (sucheResult && sucheResult.map)
    return sucheResult.map((item) => transmuteUnplausibleRechnungEvent(item));
  return [];
};

const transmuteUnplausibleRechnungItem = (
  item: UnplausibleRechnungRecord
): RechnungItem => {
  return {
    id: `${item.id}`,
    description: `${item.positionLieferant} - ${item.kurzText}`,
    selected: false,
  } as RechnungItem;
};

const transmuteUnplausibleRechnungItems = (
  sucheResult: Array<UnplausibleRechnungRecord>
): Array<RechnungItem> => {
  return sucheResult
    .sort((a, b) => a.kurzText.localeCompare(b.kurzText))
    .map((item) => transmuteUnplausibleRechnungItem(item));
};

const UnplausibleRechnungenService = {
  suche: async (
    statusItems: Array<EnumeratorItem>
  ): Promise<Array<UnplausibleRechnungRecord>> => {
    return new Promise((resolve, reject) => {
      HttpService.api
        .sendGet(`${Config.sapServiceUrl}${baseUrl}/load`, {
          params: {
            status: statusItems.map((e) => e.id).join(","),
          },
        })
        .then((response) => {
          resolve(
            transmuteUnplausibleRechnungEvents(
              response.data as RechnungenSucheResult
            )
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateStatus: async (record: UnplausibleRechnungRecord, status: string) => {
    return new Promise((resolve, reject) => {
      HttpService.api
        .sendJsonDataPost(`${Config.sapServiceUrl}${baseUrl}/updateStatus`, {
          vorgangNr: record.eventId,
          status: status,
        })
        .then((response) => {
          resolve(
            transmuteUnplausibleRechnungEvents(
              response.data as RechnungenSucheResult
            )
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  findPositions: async (referenz: string) => {
    return new Promise((resolve, reject) => {
      HttpService.api
        .sendGet(
          `${Config.sapServiceUrl}${baseUrl}/findByRegulierungsBeleg/${referenz}`
        )
        .then((response) => {
          if (response.status === 404) {
            reject("Referenz ist nicht bekannt.");
          } else resolve(transmuteUnplausibleRechnungItems(response.data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  findAutomatItems: async (
    automatSerialnummer: string
  ): Promise<Array<AutomatUnplausibleRechnungItem>> => {
    return new Promise((resolve, reject) => {
      HttpService.api
        .sendGet(
          `${Config.sapServiceUrl}${baseUrl}/findBySerialNumber/${automatSerialnummer}`
        )
        .then((response) => {
          resolve(response.data as Array<AutomatUnplausibleRechnungItem>);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEvent: async (
    positions: Array<RechnungItem>
  ): Promise<Array<UnplausibleRechnungRecord>> => {
    return new Promise((resolve, reject) => {
      HttpService.api
        .sendJsonDataPost(
          `${Config.sapServiceUrl}${baseUrl}/manuelleAnlage`,
          positions
        )
        .then((response) => {
          resolve(
            transmuteUnplausibleRechnungEvents(
              response.data as RechnungenSucheResult
            )
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default UnplausibleRechnungenService;
