import { TypographyProps } from "@mui/material";
import FieldElementInfo from "global/components/UI/FieldElementInfoBundle/FieldElementInfo";
import FieldElementInfoBundle from "global/components/UI/FieldElementInfoBundle/FieldElementInfoBundle";
import { PropsWithChildren, useEffect, useState } from "react";
import { AutomatUnplausibleRechnungItem } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungNotePopperContent from "./UnplausibleRechnungNotePopperContent";

interface UnplausibleRechnungNoteInfoProps {
  automatKey: string;
  TypographyProps?: TypographyProps;
  noteInfoItem?: AutomatUnplausibleRechnungItem;
  onNoteInfoItemSelected: (item: AutomatUnplausibleRechnungItem) => void;
  hideLabel?: boolean;
  disabled?: boolean;
}

const UnplausibleRechnungNoteInfo = <T,>(
  props: PropsWithChildren<UnplausibleRechnungNoteInfoProps>
) => {
  const [displayData, setDisplayData] =
    useState<AutomatUnplausibleRechnungItem>(
      props.noteInfoItem ?? ({} as AutomatUnplausibleRechnungItem)
    );

  const onUnplausibleRechnungItemChange = (
    item: AutomatUnplausibleRechnungItem
  ) => {
    setDisplayData(item);
    props.onNoteInfoItemSelected(item);
  };

  const popperContent = (hideAction: Function) => {
    return (
      <UnplausibleRechnungNotePopperContent
        automatKey={props.automatKey}
        hideAction={hideAction}
        setAutomatUnplausibleRechnungItem={onUnplausibleRechnungItemChange}
      />
    );
  };

  useEffect(() => {
    setDisplayData(
      props.noteInfoItem ?? ({} as AutomatUnplausibleRechnungItem)
    );
  }, [props.noteInfoItem]);

  return (
    <>
      <FieldElementInfoBundle
        label={props.hideLabel ? undefined : "Unplausible Rechnung:"}
        popperContent={popperContent}
        TypographyProps={props.TypographyProps}
        disabled={props.disabled}
      >
        <FieldElementInfo label="Vorgang-Nr:" value={displayData?.vorgang_nr} />
        <FieldElementInfo
          label="Regulierungs Beleg:"
          value={displayData?.regulierungs_beleg}
        />
      </FieldElementInfoBundle>
    </>
  );
};

export default UnplausibleRechnungNoteInfo;
