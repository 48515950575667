import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import useSnackbarGenerator from "App/hook/use-snackbars";
import { AgGridReact } from "ag-grid-react";
import { enumeratorItemForId } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import FileSelector from "global/components/UI/FileSelector/FileSelector";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import useModalController from "global/hook/modal/use-modals";
import DateUtils from "global/util/DateUtils";
import { autoSizeColumns } from "global/util/gridUtils/autoSizeColumns";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { noop } from "global/util/utils";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import NoteService from "service/data-service/notizen-controller/Note.service";
import NoteRequestDTO from "service/data-service/notizen-controller/interface/NoteRequest";
import NoteSearchResultDTO, {
  Note,
} from "service/data-service/notizen-controller/interface/NoteSearchResult";
import NoteMapper from "service/data-service/notizen-controller/mapping/NoteMapper";
import { AutomatUnplausibleRechnungItem } from "service/sap-service/interface/UnplausibleRechnungRecord";
import "../../panels.scss";
import { UNPLAUSIBLE_RECHNUNG_NOTE_TYPE } from "../NotizenPanel";
import "../notes.scss";
import NotizenController from "../script/NotizenController";
import UnplausibleRechnungNoteInfo from "./UnplausibleRechnungNoteInfo";
import { noteDialogColumnDefs } from "./columnDefinition";

interface props {
  note: Note;
  open: boolean;
  refreshNotes: Function;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const NoteDialog = (props: props) => {
  const dialogController = useModalController();
  const dataGridRef = useRef<AgGridReact>(null);
  const [noteRequest, setNoteRequest] = useState<NoteRequestDTO>(
    NoteMapper.mapNoteToNoteDTO(props.note)
  );
  const [readableAth, setReadableAth] = useState<boolean>(
    props.note.notizReadRoles === "ATH"
  );
  const [dataToGrid, setDataToGrid] = useState<NoteSearchResultDTO>({
    anzahlWiedervorlage: 0,
    rows: [],
  });

  const { showError } = useSnackbarGenerator();

  const notizTypEnumerator = useEnumerator(AUTOMATENBLATT_NOTIZTYP_ENUM_ID);
  const notizStatusEnumerator = useEnumerator(
    AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID
  );

  const accessContext = useContext(AccessContext);

  const handleClose = (
    event?: {},
    reason?: "backdropClick" | "escapeKeyDown"
  ) => {
    updateNoteRequest({
      filename: "",
      fileContent: "",
    });
    if (props.onClose) props.onClose(event, reason);
  };

  const checkMandatoryFields = () => {
    if (noteRequest.filename && !noteRequest.notizZeileText) {
      dialogController.showError(
        "Bitte füllen Sie das Feld Notizzeile aus.",
        new Error("Mandatory Notizzeile field is empty")
      );
      return false;
    }
    return true;
  };

  const updateNoteRequest = (newValue: Optional<NoteRequestDTO>) => {
    setNoteRequest((currentState) => ({ ...currentState, ...newValue }));
  };

  const retrieveSubNotes = useCallback(() => {
    NoteService.noteSearch("notizkopfKey", props.note.notizKey, (resultDTO) => {
      setDataToGrid(resultDTO);
    });
  }, [props.note]);

  useEffect(() => {
    setNoteRequest(NoteMapper.mapNoteToNoteDTO(props.note));
    setReadableAth(props.note.notizReadRoles === "ATH");
  }, [props.note]);

  useEffect(() => {
    if (props.note.notizKey !== "") {
      retrieveSubNotes();
    }
  }, [props.note, retrieveSubNotes]);

  useEffect(() => {
    var notePlaceholderItem = {} as AutomatUnplausibleRechnungItem;
    if (props.note.notizTyp === UNPLAUSIBLE_RECHNUNG_NOTE_TYPE && dataToGrid) {
      const note = dataToGrid.rows.find(
        (note: Note) => note.vorgangNr && note.notizKey === props.note.notizKey
      );
      if (note) {
        notePlaceholderItem = {
          vorgang_nr: note.vorgangNr,
          regulierungs_beleg: note.regulierungsBeleg,
        };
      }
    }
    updateNoteRequest({
      vorgangNr: notePlaceholderItem.vorgang_nr,
      regulierungsBeleg: notePlaceholderItem.regulierungs_beleg,
    });
  }, [props.note.notizTyp, dataToGrid]);

  return (
    <Dialog
      open={props.open}
      onClose={(event, reason) => {
        handleClose(event, reason);
      }}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        {`${
          enumeratorItemForId(notizTypEnumerator, noteRequest.type)?.value ?? ""
        } `}
        Notiz
      </DialogTitle>
      <DialogContent>
        <Stack className="epcom__DialogContent" spacing={2}>
          <Stack direction="row" spacing={2}>
            {props.note.notizTyp === UNPLAUSIBLE_RECHNUNG_NOTE_TYPE && (
              <UnplausibleRechnungNoteInfo
                hideLabel
                disabled
                automatKey={""}
                onNoteInfoItemSelected={noop}
                noteInfoItem={{
                  vorgang_nr: noteRequest.vorgangNr,
                  regulierungs_beleg: noteRequest.regulierungsBeleg,
                }}
                TypographyProps={{ className: "labelForNotes fontForNotes" }}
              />
            )}
            <Box className="noteDialogMultilineTextReadonlyBox">
              <TextValue
                value={noteRequest.notizKopfText}
                id={"notiz-text"}
                TextFieldProps={{
                  className: "fontForNotes noteDialogMultilineTextReadonly",
                  multiline: true,
                  inputProps: { readOnly: true },
                }}
              />
            </Box>
          </Stack>
          <Grid
            container
            className="dialogGrid"
            rowSpacing={1}
            columnSpacing={1}
            width={"auto"}
          >
            <Grid
              className="gridItemForNotes"
              item
              sm={12}
              md={4}
              lg={4}
              xl={3}
            >
              <SelectBox
                className="noteInputElement"
                label="Notiz Status:"
                enumerator={notizStatusEnumerator}
                id="notizDialog-notiz-status"
                getSelectedValue={(item) => {
                  updateNoteRequest({
                    status: item?.id.toString() ?? "",
                  });
                }}
                selection={enumeratorItemForId(
                  notizStatusEnumerator,
                  noteRequest.status
                )}
                AutocompleteProps={{
                  className: "inputTextFieldForNotes",
                }}
                TypographyProps={{ className: "labelForNotes fontForNotes" }}
              />
            </Grid>
            <Grid
              className="gridItemForNotes"
              item
              sm={12}
              md={4}
              lg={4}
              xl={3}
            >
              <DatePickerElement
                label="Wiedervorlage:"
                defaultValue={DateUtils.arrayToDate(props.note.datumFaellig)}
                getSelectedValue={(value) => {
                  updateNoteRequest({
                    wiedervorlage:
                      DateUtils.formatDateToAPIDateString(value) ?? undefined,
                  });
                }}
                id="wiedervorlage"
                TypographyProps={{ className: "labelForNotes fontForNotes" }}
                TextFieldProps={{ className: "inputTextFieldForNotes" }}
                datePickerProperties={{
                  disablePast: true,
                }}
              />
            </Grid>
            <Grid
              className="gridItemForNotes"
              item
              sm={12}
              md={4}
              lg={4}
              xl={3}
            >
              <UICheckbox
                label="Leseberechtigung:"
                value={readableAth}
                id="notizDialog-leseberechtigung"
                disabled={
                  !accessContext.getAccessContainer()
                    .showAutomatenTabCbNotizAthLeseberechtigung
                }
                onChange={(event) => {
                  setReadableAth(event.target.checked);
                  updateNoteRequest({
                    notizReadRoles: event.target.checked ? "ATH" : null,
                  });
                }}
                TypographyProps={{ className: "fontForNotes" }}
              >
                <Typography className="fontForNotes">ATH</Typography>
              </UICheckbox>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <TextValue
                label="Notizzeile:"
                value={noteRequest.notizZeileText}
                onChange={(event) => {
                  updateNoteRequest({ notizZeileText: event.target.value });
                }}
                TypographyProps={{ className: "labelForNotes fontForNotes" }}
                TextFieldProps={{
                  className: "fontForNotes noteTextInput",
                  multiline: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <FileSelector
                getFile={(file) => {
                  NotizenController.getFileNameAndContent(
                    file,
                    updateNoteRequest,
                    showError
                  );
                }}
                TypographyProps={{ className: "labelForNotes fontForNotes" }}
                TextFieldProps={{
                  className: "fontForNotes fileNameTextInput",
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={
            !accessContext.getAccessContainer()
              .showAutomatenTabBtnNotizPopupAenderungen
          }
          onClick={() => {
            if (checkMandatoryFields())
              NoteService.noteSaveOrUpdate(
                {
                  ...noteRequest,
                  notizAktion: "UPDATENOTIZ",
                },
                (data: any) => {
                  props.refreshNotes();
                  retrieveSubNotes();
                }
              );
          }}
        >
          Änderungen speichern
        </Button>
        <Button variant="contained" onClick={() => handleClose()}>
          Zurück
        </Button>
      </DialogActions>
      <DialogContent>
        <DataGrid
          dataGridRef={dataGridRef}
          columnDefs={noteDialogColumnDefs}
          rowsDataDef={{ data: dataToGrid.rows.slice(1) }}
          rowHeight={36}
          onRowDataChanged={(event) => {
            setTimeout(() => {
              autoSizeColumns(dataGridRef, ["notizText", "dateiName"]);
            }, 200);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default NoteDialog;
