import { Box, Grid, Stack } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import {
  UNPLAUSIBLE_RECHNUNGDATEN_STATUS_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useEffect, useMemo, useRef, useState } from "react";

import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import { useModalDialog } from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import UnplausibleRechnungenColumnDefs from "./grid-column-defs";

import { AgGridReact } from "ag-grid-react";
import {
  RECHNUNGEN_RESET_ACTIVE_ID,
  RECHNUNGEN_STATUS_ITEMS,
  RECHNUNGEN_STATUS_SEARCH,
  RECHNUNGEN_UPDATED_ITEMS,
  useUnplausibleRechnungen,
} from "global/hook/rechnungen/use-rechnungen-store";
import { AutomatTransponderInfo } from "global/hook/transponder/interface/Transponder";
import { useTransponder } from "global/hook/transponder/use-transponder";
import { AutomatViewHintsController } from "global/hook/viewHints/use-view-hints";
import MESSAGES from "global/messages";
import { findMachineKeyViaSerialNumberAsync } from "global/util/REST/findMachineKey";
import { HREF_AUTOMATENSUCHE } from "global/util/routes";
import { noop } from "global/util/utils";
import { UNPLAUSIBLE_RECHNUNG_NOTE_TYPE } from "page/Automatensuche/components/Automatenblatt/components/panel/Notizen/NotizenPanel";
import { useNavigate } from "react-router-dom";
import DepositMachineDataService from "service/data-service/automate-controller/DepositMachineData.service";
import ExportService from "service/report-service/export/Export.service";
import { UnplausibleRechnungRecord } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import UnplausibleRechnungenGridActions from "./component/UnplausibleRechnungenDataGridActions";
import {} from "./controller/UnplausibleRechnungenController";
import "./unplausiblerechnungen.scss";

const FILTER_DEFAULT: Array<string> = ["IN_PROGRESS"];

const UnplausibleRechnungen: React.FC = () => {
  const statusEnumerator = useEnumerator(
    UNPLAUSIBLE_RECHNUNGDATEN_STATUS_ENUM_ID
  );

  const [dialogHelper, dialogController] = useModalDialog(
    "unplausible-rechnungen-add"
  );

  const [rechnungenStore, rechnungenDispatcher] = useUnplausibleRechnungen();
  const [eventRecords, setEventRecords] = useState<
    Array<UnplausibleRechnungRecord>
  >([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [statusOptions, setStatusOptions] = useState<Array<React.ReactNode>>(
    []
  );
  const scrollToRef = useRef<number>(0);
  const gridRef = useRef<AgGridReact>(null);

  const windowViewport = useWindowViewport(0, 242);

  const transponderCtrl = useTransponder()[1];

  const navigate = useNavigate();

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  useEffect(() => {
    resetFilters();
    filterHandler();

    setStatusOptions(
      statusEnumerator.items.map((item) => {
        return (
          <option value={item.id} key={item.id}>
            {item.value}
          </option>
        );
      })
    ); // eslint-disable-next-line
  }, [statusEnumerator]);

  function filterHandler(scrollID?: number) {
    scrollToRef.current = scrollID ?? 0;
    rechnungenDispatcher(RECHNUNGEN_STATUS_SEARCH);
  }

  const resetFilters = () => {
    let filterItems = FILTER_DEFAULT;
    const items = statusEnumerator.items.filter((e) =>
      filterItems.includes(e.id as string)
    );
    setResetFilter((state) => !state);
    rechnungenDispatcher(RECHNUNGEN_STATUS_ITEMS, {
      queryStatusItems: items,
    });
  };

  function exportHandler() {
    ExportService.downloadUnplausibleRechnungen(
      rechnungenStore.statusItems.map((e) => e.id).join(",")
    );
  }

  function onRowRecordClick(
    record: UnplausibleRechnungRecord,
    columnId: string
  ) {
    if (columnId === "latestNote") {
      onNoteClick(record);
    }
  }

  const getAutomatTransponderInfoForMarket = async (
    gln: string
  ): Promise<AutomatTransponderInfo> => {
    const data = await DepositMachineDataService.retrieveMarketMachineData(gln);
    if (data.length === 0) {
      throw new Error(MESSAGES.UNPLAUSIBLE_NO_AUTOMAT_GLN); //"No automat found for the given rnsGln"
    }

    const automat = data.reduce((minRecord, item) =>
      item.automatSerienNr < minRecord.automatSerienNr ? item : minRecord
    );

    return {
      seriennr: automat.automatSerienNr,
      automatKey: automat.automatkey,
    } as AutomatTransponderInfo;
  };

  const getAutomatTransponderInfoForRecord = async (
    record: UnplausibleRechnungRecord
  ): Promise<AutomatTransponderInfo> => {
    if (!record.noteAutomatSerialNumber && !record.seriennr && !record.rnsGln) {
      throw new Error(MESSAGES.UNPLAUSIBLE_MISSING_IDENTIFIER); //"Either seriennr or rnsGln must be provided"
    }

    const automatSerialNumber =
      record.noteAutomatSerialNumber ?? record.seriennr;
    if (automatSerialNumber) {
      const key = await findMachineKeyViaSerialNumberAsync(automatSerialNumber);
      return {
        seriennr: automatSerialNumber,
        automatKey: key,
      } as AutomatTransponderInfo;
    }

    if (record.rnsGln) {
      return await getAutomatTransponderInfoForMarket(record.rnsGln);
    }

    // TypeScript will ensure this is unreachable due to the initial check
    throw new Error(MESSAGES.UNPLAUSIBLE_UNEXPECTED_PATH); //"Unexpected path"
  };

  async function onNoteClick(record: UnplausibleRechnungRecord) {
    try {
      const automatInfo = await getAutomatTransponderInfoForRecord(record);

      if (!automatInfo.automatKey) {
        throw new Error(MESSAGES.UNPLAUSIBLE_NO_AUTOMAT_KEY); //"No automat key found"
      }

      AutomatViewHintsController.registerAutomatViewHints({
        automatKey: automatInfo.automatKey,
        infoPanelIndex: 7,
        notePanelData: record.noteKey
          ? { noteKey: record.noteKey }
          : {
              type: UNPLAUSIBLE_RECHNUNG_NOTE_TYPE,
              rechnungItem: {
                vorgang_nr: `${record.eventId}`,
                regulierungs_beleg: record.regulierungsBeleg,
              },
            },
      });
      transponderCtrl.setTransponderData(automatInfo);
      navigate(HREF_AUTOMATENSUCHE);
    } catch (error: Error | any) {
      dialogController.showDialog({
        message: error.message,
        title: MESSAGES.UNPLAUSIBLE_ERROR_PROCESSING, //"Error processing note click",
        onOkClick: noop,
      });
    }
  }

  const buildDropDownNode = (
    eventRecord: UnplausibleRechnungRecord
  ): React.ReactNode => {
    const onSelectionChange = async (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const data = (await UnplausibleRechnungenService.updateStatus(
        eventRecord,
        event.target.value
      )) as Array<UnplausibleRechnungRecord>;
      rechnungenDispatcher(RECHNUNGEN_UPDATED_ITEMS, {
        records: data,
      });

      dialogController.showDialog({
        message: MESSAGES.UNPLAUSIBLE_RECORD_CHANGE_COUNT(data.length),
        title: MESSAGES.UNPLAUSIBLE_STATUS_CHANGE_TITLE,
        onOkClick: noop,
      });
    };

    return (
      <Box className="box-with-centered-content">
        <select
          className="unplausibleRechnungen__dataGrid__statusOptions"
          name="statusOptions"
          value={eventRecord.status}
          onChange={onSelectionChange}
        >
          {statusOptions}
        </select>
      </Box>
    );
  };

  var task: NodeJS.Timeout;
  useEffect(() => {
    const data = rechnungenStore.result.map((item) => {
      return {
        ...item,
        statusNode: buildDropDownNode(item),
      };
    });
    setEventRecords(data);

    const scrollToIndex = () => {
      const scrollTo =
        scrollToRef.current > 0
          ? scrollToRef.current
          : rechnungenStore.activeEventId;
      if (scrollTo > 0) {
        const index = data.findIndex((item) => item.eventId === scrollTo);

        if (index >= 0) {
          gridRef.current?.api?.ensureIndexVisible(index, "top");
          gridRef.current?.api
            ?.getRowNode(index.toString())
            ?.setSelected(true, true, "api");
        }
        scrollToRef.current = 0;
        rechnungenDispatcher(RECHNUNGEN_RESET_ACTIVE_ID);
      }
    };

    task = setTimeout(() => {
      clearTimeout(task);
      scrollToIndex();
    }, 100);
  }, [rechnungenStore.result]);

  return (
    <Stack columnGap={2} className="mt_1rem ml_1rem">
      <Grid container spacing={2} width={"auto"}>
        <Grid item xs={12}>
          <Box className="uiElement rechnungen-status-multiselect">
            <MultipleSelectCheckbox
              limitTags={5}
              label="Status:"
              defaultValues={FILTER_DEFAULT}
              id="status"
              options={statusEnumerator.items}
              onChange={(values) => {
                rechnungenDispatcher(RECHNUNGEN_STATUS_ITEMS, {
                  queryStatusItems: values,
                });
              }}
              resetSelectedValues={resetFilter}
              AutocompleteProps={{
                className: "inputTextFieldForKaufanfragenStatus",
              }}
              TypographyProps={{ className: "dialogTextStyle" }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            dataGridRef={gridRef}
            height={windowViewport.height}
            columnDefs={UnplausibleRechnungenColumnDefs}
            gridActions={
              <UnplausibleRechnungenGridActions
                resetTrigger={resetFilters}
                reloadTrigger={filterHandler}
                dialogController={dialogController}
              />
            }
            gridOptions={{
              defaultColDef: defaultColDef,
            }}
            rowsDataDef={{ data: eventRecords }}
            exportDef={{ action: exportHandler }}
            onRowClicked={onRowRecordClick}
          />
        </Grid>
      </Grid>
      {dialogHelper && <ModalMessageDialog {...dialogHelper} />}
    </Stack>
  );
};

export default UnplausibleRechnungen;
