import { Button, Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import { FETCH_LOGFILE_RECORDS } from "global/hook/datastore/automat/automat-logfile-store";
import { AutomatActionPayload } from "global/hook/datastore/interface/AutomatAction";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import {
  AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils from "global/util/DateUtils";
import { useEffect, useState } from "react";
import LogfileZeile from "service/logfile-service/interface/LogfileZeile";
import ExportService from "service/report-service/export/Export.service";
import LogfilePanelColumnDefs from "./grid-column-defs";
import { LogfilePanelFilterOptions } from "./interface/LogfilePanelFilterOptions";

import { AutomatViewHints } from "global/hook/viewHints/interface/AutomatViewHintsController";
import { useAutomatViewHints } from "global/hook/viewHints/use-view-hints";
import "./logfile.scss";

interface LogfilePanelProps {
  automatKey: string;
}

const DEFAULT_START_DATE_INFO = "unbekannt";

const LogfilePanel = (props: LogfilePanelProps) => {
  const [automatData, dispatchAction] = useAutomatData(props.automatKey);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [viewHints, hintsUpdateDispatch] = useAutomatViewHints(
    props.automatKey
  );

  const [filterOptions, setFilterOptions] = useState<LogfilePanelFilterOptions>(
    {
      logfileDateFrom: viewHints.logfileDateFrom,
      logfileDateTo: viewHints.logfileDateTo,
      logfileKategory: viewHints.logfileKategory,
    }
  );

  const [canFetchRecords, setCanFetchRecords] = useState<boolean>();

  const [records, setRecords] = useState<Array<LogfileZeile> | undefined>(
    automatData.logfileRecords
  );
  const [startDateInfo, setStartDateInfo] = useState<string>(
    DEFAULT_START_DATE_INFO
  );
  const windowViewport = useWindowViewport(0, 390);

  const updateFilterOptions = (options: LogfilePanelFilterOptions) =>
    hintsUpdateDispatch({
      ...options,
    } as AutomatViewHints);

  useEffect(() => {
    setCanFetchRecords(
      filterOptions.logfileDateFrom &&
        filterOptions.logfileDateTo &&
        (filterOptions.logfileKategory ?? []).length > 0
    );
  }, [filterOptions]);

  useEffect(() => {
    setFilterOptions({
      logfileDateFrom: viewHints.logfileDateFrom,
      logfileDateTo: viewHints.logfileDateTo,
      logfileKategory: viewHints.logfileKategory,
    });
  }, [viewHints]);

  const logfileTypEnumerator = useEnumerator(
    AUTOMATENBLATT_LOGFILEKATEGORIE_ENUM_ID
  );

  useEffect(() => {
    const allRecords = automatData.logfileRecords ?? [];
    setRecords(allRecords);
    setStartDateInfo(
      allRecords.length === 0
        ? DEFAULT_START_DATE_INFO
        : `${allRecords[0].datenbestand} Uhr`
    );
  }, [automatData.logfileRecords]);

  const fetchLogfileRecords = () => {
    dispatchAction(
      FETCH_LOGFILE_RECORDS,
      {
        ...filterOptions,
      } as AutomatActionPayload,
      undefined,
      setIsFetchingData
    );
  };

  const exportLogfileRecords = () => {
    ExportService.downloadAutomatLogfile(
      `${automatData.automatenblattInformation.seriennummer}`,
      DateUtils.formatDateToAPIDateString(
        filterOptions.logfileDateFrom,
        "dd.MM.yyyy"
      ) ?? "",
      DateUtils.formatDateToAPIDateString(
        filterOptions.logfileDateTo,
        "dd.MM.yyyy"
      ) ?? "",
      (filterOptions.logfileKategory ?? []).map((e) => e.id).join(",")
    );
  };

  return (
    <Stack rowGap={0.5}>
      <Grid container spacing={2} width={"auto"}>
        <Grid item xs={12}>
          <Typography variant={"body1"}>
            Datenbestand vom: {startDateInfo}
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-start" spacing={2} item xs={12}>
          <Grid item xs={12} lg={5} xl={4}>
            <DatePickerElement
              label="Datum von:"
              defaultValue={filterOptions.logfileDateFrom}
              getSelectedValue={(value) => {
                updateFilterOptions({
                  logfileDateFrom: value,
                } as LogfilePanelFilterOptions);
              }}
              id="datum-von"
              TypographyProps={{ className: "labelForLogfile fontForNotes" }}
              TextFieldProps={{ className: "logfile__inputTextFieldForDate" }}
            />
          </Grid>
          <Grid item xs={12} lg={5} xl={4}>
            <DatePickerElement
              label="Datum bis:"
              defaultValue={filterOptions.logfileDateTo}
              getSelectedValue={(value) => {
                updateFilterOptions({
                  logfileDateTo: value,
                } as LogfilePanelFilterOptions);
              }}
              id="datum-bis"
              TypographyProps={{ className: "labelForLogfile fontForNotes" }}
              TextFieldProps={{ className: "logfile__inputTextFieldForDate" }}
            />
          </Grid>
          <Grid item lg={2} display={{ md: "none", lg: "flex", xl: "none" }}>
            {/* this is just empty space */}
          </Grid>
          <Grid item xs={12} xl={4}>
            <MultipleSelectCheckbox
              label="Suchbegriff:"
              defaultValues={filterOptions.logfileKategory?.map(
                (item) => item.id
              )}
              options={logfileTypEnumerator.items}
              id="suchbegriff"
              onChange={(values) => {
                updateFilterOptions({
                  logfileKategory: values,
                } as LogfilePanelFilterOptions);
              }}
              AutocompleteProps={{
                className: "logfile__AutocompleteContainer",
              }}
              TypographyProps={{
                className: "fontForNotes labelForLogfile",
              }}
              StackProps={{ columnGap: 2 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <DataGrid
        height={windowViewport.height}
        columnDefs={LogfilePanelColumnDefs}
        rowsDataDef={{
          data: records,
          isFetchingData: isFetchingData,
        }}
        exportDef={{
          action: exportLogfileRecords,
          disabled: !canFetchRecords,
        }}
        gridActions={
          <Button
            size="small"
            variant="contained"
            disabled={!canFetchRecords}
            onClick={fetchLogfileRecords}
          >
            Filter anwenden
          </Button>
        }
      />
    </Stack>
  );
};

export default LogfilePanel;
