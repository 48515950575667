import { Button, Grid, Stack, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import useSnackbarGenerator from "App/hook/use-snackbars";
import { enumeratorItemForId } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { NotePanelData } from "global/hook/viewHints/interface/AutomatViewHintsController";
import { useAutomatViewHints } from "global/hook/viewHints/use-view-hints";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { Optional } from "global/util/interface/MappedTypesTransform";
import { useContext, useEffect, useState } from "react";
import NoteService from "service/data-service/notizen-controller/Note.service";
import { Note } from "service/data-service/notizen-controller/interface/NoteSearchResult";
import { generateDefaultNoteRequest } from "service/data-service/notizen-controller/mapping/generator/defaultNoteDTOGenerator";
import "../panels.scss";
import NoteDialog from "./components/NoteDialog";
import UnplausibleRechnungNoteInfo from "./components/UnplausibleRechnungNoteInfo";
import { generateDefaultNote } from "./generator/defaultNote";
import "./notes.scss";
import NotizenController from "./script/NotizenController";
import { NotizenPanelColumnDefs } from "./script/columnDefinition";

interface NotizenPanelProps {
  automatKey: string;
  disabled?: boolean;
}

export const UNPLAUSIBLE_RECHNUNG_NOTE_TYPE = "UNPLAUSIBLE_RECHNUNGEN";

const NotizenPanel = (props: NotizenPanelProps) => {
  const snackbarGenerator = useSnackbarGenerator();
  const notizTypEnumerator = useEnumerator(AUTOMATENBLATT_NOTIZTYP_ENUM_ID);
  const notizStatusEnumerator = useEnumerator(
    AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID
  );

  const [viewHints, updateViewHints] = useAutomatViewHints(
    props.automatKey,
    false
  );

  const [notePanelData, setNotePanelData] = useState({
    ...(viewHints.notePanelData ?? ({} as NotePanelData)),
  });

  const [notes, setNotes] = useState<Array<Note>>();

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);
  const [noteToDialog, setNoteToDialog] = useState<Note>(generateDefaultNote());
  const accessContext = useContext(AccessContext);

  const windowViewport = useWindowViewport(0, 415);

  function clearPanelData() {
    updateNotePanelData({
      text: "",
      status: "",
      type: "",
      date: "",
      athFlag: false,
      rechnungItem: undefined,
    });
  }

  const updateNotePanelData = (data: Optional<NotePanelData>) => {
    setNotePanelData((prevData) => {
      const freshData = { ...prevData, ...data } as NotePanelData;
      updateViewHints({
        ...viewHints,
        notePanelData: freshData,
      });
      return freshData;
    });
  };

  const buildNoteRequestDTO = () => {
    const noteRequest = generateDefaultNoteRequest(props.automatKey);
    noteRequest.notizAktion = "SAVENEWNOTIZ";
    noteRequest.notizKopfText = (notePanelData.text ?? "").trim();
    noteRequest.type = notePanelData.type ?? "";
    noteRequest.status = notePanelData.status ?? "";
    noteRequest.wiedervorlage = notePanelData.date ?? "";
    noteRequest.notizReadRoles = notePanelData.athFlag ? "ATH" : null;
    noteRequest.vorgangNr = notePanelData.rechnungItem?.vorgang_nr ?? "";
    noteRequest.regulierungsBeleg =
      notePanelData.rechnungItem?.regulierungs_beleg ?? "";
    return noteRequest;
  };

  function handleNewNoteClick() {
    const noteRequest = buildNoteRequestDTO();
    if (NotizenController.isValidNewNoteInput(noteRequest, snackbarGenerator)) {
      NoteService.noteSaveOrUpdate(noteRequest, (data: any) => {
        setNotes((existingNotes) => {
          const freshNotes = [data, ...(existingNotes ?? [])] as Array<Note>;
          updateNotePanelData({ notes: freshNotes });
          return freshNotes;
        });
        clearPanelData();
      });
    }
  }

  const showNote = (note: Note) => {
    setShowNoteDialog(true);
    setNoteToDialog(note);
  };

  const retrieveNotes = () => {
    setIsFetchingData(true);

    NoteService.noteSearch(
      "automatKey",
      props.automatKey,
      (searchResult) => {
        setIsFetchingData(false);
        setNotes(searchResult.rows);
        updateNotePanelData({
          notes: searchResult.rows,
        });
      },
      () => {
        setIsFetchingData(false);
      }
    );
  };

  useEffect(() => {
    const notePanelData = viewHints.notePanelData ?? ({} as NotePanelData);
    setNotePanelData(notePanelData);

    if (!notePanelData.notes) {
      retrieveNotes();
    } else {
      setNotes(notePanelData.notes);
      setIsFetchingData(false);
    }
  }, [viewHints]);

  useEffect(() => {
    const noteKey = notePanelData.noteKey;
    if (notes && noteKey) {
      const note = notes.find((note) => note.notizKey === noteKey);
      if (note) showNote(note);
      updateNotePanelData({ noteKey: "" });
    }
  }, [notes]);

  return (
    <Stack spacing={2}>
      <Grid
        container
        className="gridForNotes"
        rowSpacing={1}
        columnSpacing={2}
        width={"auto"}
      >
        <Grid
          className="gridItemForNotes"
          item
          md={12}
          lg={6}
          order={{ xs: 1 }}
        >
          <SelectBox
            className="noteInputElement"
            label="Notiz Typ:"
            enumerator={notizTypEnumerator}
            id="notiz-typ"
            getSelectedValue={(item) => {
              updateNotePanelData({
                type: item?.id.toString() ?? "",
              });
            }}
            selection={enumeratorItemForId(
              notizTypEnumerator,
              notePanelData.type ?? ""
            )}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          md={12}
          lg={6}
          order={{ xs: 2, md: 2, lg: 3 }}
        >
          <SelectBox
            className="noteInputElement"
            label="Notiz Status:"
            enumerator={notizStatusEnumerator}
            id="notiz-status"
            getSelectedValue={(item) => {
              updateNotePanelData({
                status: item?.id.toString() ?? "",
              });
            }}
            selection={enumeratorItemForId(
              notizStatusEnumerator,
              notePanelData.status ?? ""
            )}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          md={12}
          lg={6}
          order={{ xs: 3, md: 3, lg: 2 }}
        >
          <DatePickerElement
            className="datePickerForNotes noteInputElement"
            label="Wiedervorlage:"
            getSelectedValue={(value) => {
              updateNotePanelData({
                date:
                  DateUtils.formatDateToAPIDateString(value ?? undefined) ?? "",
              });
            }}
            defaultValue={DateUtils.arrayToDate(
              DateUtils.apiStringToNumberArray(notePanelData.date ?? "")
            )}
            id="wiedervorlage"
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
            TextFieldProps={{ className: "inputTextFieldForNotes" }}
            datePickerProperties={{
              minDate: getTomorrow(),
              disablePast: true,
            }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          xs={12}
          md={12}
          lg={6}
          order={{ xs: 4 }}
        >
          <UICheckbox
            disabled={
              !accessContext.getAccessContainer()
                .showAutomatenTabCbNotizAthLeseberechtigung
            }
            label="Leseberechtigung:"
            value={notePanelData.athFlag ?? false}
            id="leseberechtigung"
            onChange={(event) => {
              updateNotePanelData({
                athFlag: event.target.checked,
              });
            }}
            TypographyProps={{ className: "fontForNotes" }}
          >
            <Typography className="fontForNotes">ATH</Typography>
          </UICheckbox>
        </Grid>

        {notePanelData.type === UNPLAUSIBLE_RECHNUNG_NOTE_TYPE && (
          <Grid
            item
            className="gridItemForNotes"
            md={12}
            lg={12}
            order={{ xs: 5 }}
          >
            <UnplausibleRechnungNoteInfo
              automatKey={props.automatKey}
              onNoteInfoItemSelected={(item) => {
                updateNotePanelData({
                  rechnungItem: item,
                });
              }}
              noteInfoItem={notePanelData.rechnungItem}
              TypographyProps={{ className: "labelForNotes fontForNotes" }}
            />
          </Grid>
        )}
        <Grid item md={12} lg={12} order={{ xs: 6 }}>
          <Typography className="labelForNotes fontForNotes">
            Notiz Text:
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} order={{ xs: 7 }}>
          <TextValue
            value={notePanelData.text ?? ""}
            id={"notiz-text"}
            onChange={(event) => {
              updateNotePanelData({
                text: event.target.value,
              });
            }}
            TextFieldProps={{
              className: "fontForNotes noteTextInput",
              multiline: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        className="createNewNoteButton"
        disabled={
          !accessContext.getAccessContainer().showAutomatenTabBtnNotizAnlegen ||
          props.disabled
        }
        onClick={handleNewNoteClick}
      >
        Neue Notiz anlegen
      </Button>

      <DataGrid
        height={windowViewport.height}
        columnDefs={NotizenPanelColumnDefs}
        rowsDataDef={{
          data: notes,
          isFetchingData: isFetchingData,
        }}
        onRowClicked={(row) => showNote(row)}
      />
      <NoteDialog
        note={noteToDialog}
        open={showNoteDialog}
        refreshNotes={retrieveNotes}
        onClose={() => setShowNoteDialog(false)}
      />
    </Stack>
  );
};

export default NotizenPanel;
